import { graphql, HeadFC, PageProps } from "gatsby";
import React from "react";
import Footer from "../components/footer";
import HtmlText from "../components/htmlText";
import Seo from "../components/seo";

const Impressum: React.FC<PageProps<Queries.ImpressumQuery>> = ({ data }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="container mx-auto py-16">
        <HtmlText text={data.prismicImpressum?.data.impressum_text?.richText} />
      </main>
      <Footer footer={data} activePage={data.prismicImpressum!} />
    </div>
  );
};

export const query = graphql`
  query Impressum($lang: String) {
    ...Footer
    ...Global

    prismicImpressum(lang: { eq: $lang }) {
      id
      lang
      type
      url
      data {
        impressum_text {
          richText
        }
      }
    }
  }
`;

export default Impressum;

export const Head: HeadFC = () => <Seo />;
